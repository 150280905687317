<template>
  <div v-if="dialogVisible">
    <el-dialog
      :title="!form.id ? '新增会议' : isedite ? '修改会议' : '查看会议'"
      :visible.sync="dialogVisible"
      width="75%"
      :before-close="resetform"
    >
      <div
        class="addbox"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div class="infobox">
          <div class="left_info">
            <div class="item_line" style="font-size: 0.2rem">
              {{ form.name }}
            </div>
            <div class="item_line">
              <span> <i class="el-icon-time"></i> 报名截止时间：</span>
              <span class="valuename">{{ form.endtime }}</span>
            </div>
            <div class="item_line">
              <span> <i class="el-icon-time"></i> 会议开始时间：</span>
              <span class="valuename">{{ form.addtime }}</span>
            </div>
            <div class="item_line">
              <span>
                <i class="el-icon-location-information"></i> 会议地点：</span
              >
              <span class="valuename">{{ form.address }}</span>
            </div>
            <div class="item_line">
              <span> 会议类型：</span>
              <span class="valuename">{{
                hylxlist.find((item) => item.id == form.wymeetingtype_id).name
              }}</span>
            </div>
            <div class="item_line" style="align-items: flex-start">
              <div style="display: flex; align-items: center; color: #da8d3f">
                已读（{{ form.yd }}）人：
              </div>
              <div style="width: 80%">
                <span class="valuename" v-for="ryName in form.ydry"
                  >{{ ryName.name }};</span
                >
              </div>
            </div>
            <div
              class="item_line"
              style="align-items: flex-start; margin-bottom: 20px"
            >
              <div style="display: flex; align-items: center; color: #da8d3f">
                未读（{{ form.wd }}）人：
              </div>
              <div style="width: 80%">
                <span class="valuename" v-for="item in form.wdry"
                  >{{ item.name }};</span
                >
              </div>
            </div>
            <div class="item_line">
              <div
                style="
                  display: flex;
                  align-items: center;
                  color: #da8d3f;
                  margin-right: 30px;
                  cursor: pointer;
                "
                @click="showbm"
              >
                <i
                  class="el-icon-document-checked"
                  style="font-size: 22px; margin-right: 10px"
                ></i>
                <span
                  >已报名（<span style="color: rgb(222, 57, 57)">{{
                    form.bm
                  }}</span
                  >）人</span
                >
              </div>
              <div
                v-show="!istz"
                style="
                  display: flex;
                  align-items: center;
                  color: #da8d3f;
                  cursor: pointer;
                "
                @click="showqj"
              >
                <i
                  class="el-icon-document-remove"
                  style="font-size: 22px; margin-right: 10px"
                ></i>
                <span
                  >已请假（<span style="color: rgb(222, 57, 57)">{{
                    form.qj
                  }}</span
                  >）人</span
                >
              </div>
            </div>
            <div class="item_line" v-show="!istz">
              <el-button type="primary" size="mini" @click="showbm"
                >报名管理</el-button
              >
              <el-button type="primary" size="mini" @click="showqd"
                >签到管理</el-button
              >
              <el-button type="primary" size="mini" @click="showqjtr"
                >请假管理</el-button
              >
              <el-button type="success" size="mini" @click="daochu"
                >人员与会状态导出</el-button
              >
            </div>
            <div class="item_line" v-show="istz">
              <el-button
                v-show="
                  form.wymeetingtz_qdtype != 2 &&
                  (form.wymeetingtz_type == 1 || form.wymeetingtz_type == 2)
                "
                type="primary"
                size="mini"
                @click="canhui(form)"
              >
                参会
              </el-button>
              <el-button
                v-show="
                  form.wymeetingtz_qdtype != 2 && form.wymeetingtz_type == 3
                "
                type="primary"
                size="mini"
                @click="qiandao(form)"
              >
                签到
              </el-button>
              <el-button
                v-show="
                  form.wymeetingtz_qdtype != 2 &&
                  (form.wymeetingtz_type == 1 || form.wymeetingtz_type == 2)
                "
                type="primary"
                size="mini"
                @click="qingjia(form)"
              >
                请假
              </el-button>
              <el-button
                v-show="form.wymeetingtz_qdtype == 2"
                type="primary"
                size="mini"
                disabled
              >
                已签到
              </el-button>
              <el-button
                v-show="form.wymeetingtz_type == 4"
                type="primary"
                size="mini"
                disabled
              >
                已请假
              </el-button>
            </div>
            <div class="item_line">
              <div
                class="tapmenu"
                :class="tapindex == index ? 'active_tap' : ''"
                v-for="(item, index) in tap"
                :key="index"
                @click="changetap(index)"
              >
                {{ item }}
              </div>
            </div>

            <info
              v-show="tapindex == 0"
              :updataQx="updataQx"
              ref="myinfo"
            ></info>
            <!-- <yiti_upolad :istz="istz" :updataQx="updataQx" v-show="tapindex==1" ref="yitiupolad" @getinfo="getinfo"></yiti_upolad> -->
            <richeng_upolad
              :istz="istz"
              :updataQx="updataQx"
              v-show="tapindex == 1"
              ref="richengupolad"
              @getinfo="getinfo"
            ></richeng_upolad>
            <!-- <yicheng_upolad :istz="istz" :updataQx="updataQx" v-show="tapindex==2" ref="yichengupolad"
							@getinfo="getinfo"></yicheng_upolad> -->
            <file_upolad
              :istz="istz"
              :updataQx="updataQx"
              v-show="tapindex == 2"
              ref="fileupolad"
              @getinfo="getinfo"
            ></file_upolad>
            <zuoxitongji
              :istz="istz"
              :updataQx="updataQx"
              v-show="tapindex == 3"
              ref="zuoxitongji"
              @getinfo="getinfo"
            ></zuoxitongji>
          </div>
        </div>
        <div class="btnbox" v-show="tapindex != 3">
          <el-button
            v-show="isedite"
            type="primary"
            size="mini"
            class="font14"
            >{{ form.id ? "保存修改" : "保存提交" }}</el-button
          >
          <el-button
            type="primary"
            size="mini"
            class="font14"
            @click="resetform"
            >关闭</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- <selectuser ref="myselectuser" @selectedry="selectedry"></selectuser> -->

    <!--报名管理-->
    <baoming_mng ref="baoming"></baoming_mng>
    <!--查看座次-->
    <look_zuoci ref="lookzuoci"></look_zuoci>
    <!--签到管理-->
    <qiandao_mng ref="qiandao"></qiandao_mng>
    <!--请假管理-->
    <qingjia_mng ref="qingjia"></qingjia_mng>

    <!--请假理由-->
    <el-dialog
      title="请假原因"
      :visible.sync="showqj"
      width="30%"
      :before-close="clearqj"
    >
      <div>
        <div style="display: flex; align-items: flex-start">
          <span>请假理由：</span>
          <el-input
            v-model="qjliyou"
            placeholder="请输入"
            style="width: 70%"
            type="textarea"
            :rows="4"
          ></el-input>
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px">
          <span>代参加人：</span>
          <el-input
            v-model="qjdch"
            placeholder="请输入"
            style="width: 70%"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearqj">取 消</el-button>
        <el-button type="primary" @click="postqingjia">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import selectuser from '@/components/select_user.vue'
import XLSX from "xlsx-js-style";
import info from "./info.vue";
import yiti_upolad from "./yiti_upolad.vue";
import richeng_upolad from "./richeng_upolad.vue";
import yicheng_upolad from "./yicheng_upolad.vue";
import file_upolad from "./file_upolad.vue";
import zuoxitongji from "./zuoxitongji.vue";
import baoming_mng from "./baoming_mng.vue";
import qiandao_mng from "./qiandao_mng.vue";
import qingjia_mng from "./qingjia_mng.vue";
import look_zuoci from "./look_zuoci.vue";
export default {
  components: {
    info,
    yiti_upolad,
    richeng_upolad,
    yicheng_upolad,
    file_upolad,
    zuoxitongji,
    baoming_mng,
    qiandao_mng,
    qingjia_mng,
    look_zuoci,
  },
  props: {
    istz: {
      type: Boolean,
      default: false,
    },
    updataQx: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dialogVisible: false,
      showdetailbox: true,
      isedite: true,
      fathername: "",
      form: {
        name: "",
        addtime: "",
        address: "",
        chuser_ids: "",
        chuser_name: "",
        lxuser_ids: "",
        lxuser_name: "",
        endtime: "",
        fw: "",
      },
      selectindex: 1,
      roomoptions: [], //会议室
      hylxlist: [],
      tap: [],
      tapindex: 0,
      showqj: false,
      qjliyou: "",
      qjdch: "",
      qjid: "",
    };
  },
  created() {
    if (!this.istz) {
      this.tap = ["会议信息", "日程上传", "文件上传"];
    } else {
      this.tap = ["会议信息", "日程查看", "文件查看"];
    }
    this.gethylxlist();
  },
  watch: {
    dialogVisible(newval, oldval) {
      if (newval) {
        this.$nextTick(() => {
          this.$refs.myinfo.form = this.form;
          this.$refs.myinfo.infoshow = true;
        });
      } else {
        this.tapindex = 0;
      }
    },
  },
  methods: {
    gethylxlist() {
      this.$post({
        url: "/api/wymeetingtype/index",
        params: {},
      }).then((res) => {
        this.hylxlist = res.list;
      });
    },
    daochu() {
      // window.open(this.$URL+'/api/user/hyqddc?token='+this.$store.state.userinfo?.token,'_blank')

      this.$post({
        url: "/api/user/hyqddc",
        params: {
          id: this.form.id,
        },
      }).then((res) => {
        // 创建工作簿
        let wb = XLSX.utils.book_new();

        // 创建工作表数据
        let ws_data = [
          [this.form.name + "人员与会状态"], // 第一行，后面将合并单元格
          ["参会编号", "姓名", "联系方式", "与会状态", "人员类型"], // 第二行，列头
        ];
        res.list.forEach((participant) => {
          let row = [
            participant.code,
            participant.name,
            participant.phone,
            participant.qdzt,
            participant.flname,
          ];
          ws_data.push(row);
        });

        // 创建带有数据的工作表
        let ws = XLSX.utils.aoa_to_sheet(ws_data);

        // 设置工作表的列宽
        ws["!cols"] = [
          { wch: 20 }, // 参会编号
          { wch: 20 }, // 姓名列宽
          { wch: 20 }, // 联系方式列宽
          { wch: 20 }, // 参加状态列宽
          { wch: 20 }, // 人员类型
        ];

        // 第一行标题样式
        const titleStyle = {
          font: {
            name: "宋体",
            sz: 18,
            bold: true,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
        };

        // 第二行列头样式
        const headerStyle = {
          font: {
            name: "宋体",
            sz: 15,
            bold: true,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        // 数据样式
        const dataStyle = {
          font: {
            name: "宋体",
            sz: 14,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        // 应用标题样式并合并第一行的单元格
        XLSX.utils.sheet_add_aoa(ws, [[this.form.name + "人员与会状态"]], {
          origin: "A1",
        });
        ws["A1"].s = titleStyle;
        ws["!merges"] = [XLSX.utils.decode_range("A1:E1")];

        // 应用列头样式
        ["A2", "B2", "C2", "D2", "E2"].forEach((key) => {
          ws[key].s = headerStyle;
        });

        // 应用数据样式
        for (let R = 3; R <= ws_data.length; R++) {
          for (let C = 65; C <= 69; C++) {
            // ASCII 码 65 是 'A', 67 是 'C'
            let cell_ref = String.fromCharCode(C) + R; // 生成单元格引用，例如 'A3'
            ws[cell_ref].s = dataStyle;
          }
        }

        // 把工作表添加到工作簿
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // 定义导出的文件名
        let exportFileName = this.form.name + "人员与会状态.xlsx";

        // 导出 Excel 文件
        XLSX.writeFile(wb, exportFileName, {
          bookType: "xlsx",
          type: "binary",
        });
      });
    },
    canhui(row) {
      this.$confirm("请确认是否参加会议?", "提示", {
        confirmButtonText: "参加",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post({
            url: "/api/wymeetingtz/hybmqj",
            params: {
              id: row.wymeetingtz_id,
              type: 3,
            },
          }).then((res) => {
            this.$message.success("参加成功");
            this.dialogVisible = false;
            this.$parent.getlist();
          });
        })
        .catch(() => {});
    },
    qiandao(row) {
      this.$confirm("请确认是否签到会议?", "提示", {
        confirmButtonText: "签到",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post({
            url: "/api/wymeetingtz/hyqd",
            params: {
              id: row.wymeetingtz_id,
              qdfs: 1,
            },
          }).then((res) => {
            this.$message.success("签到成功");
            this.dialogVisible = false;
            this.$parent.getlist();
          });
        })
        .catch(() => {});
    },
    qingjia(row) {
      this.qjid = row.wymeetingtz_id;
      this.showqj = true;
    },
    postqingjia() {
      this.$post({
        url: "/api/wymeetingtz/hybmqj",
        params: {
          id: this.qjid,
          type: 4,
          liyou: this.qjliyou,
          dname: this.qjdch,
        },
      }).then((res) => {
        this.$message.success("操作成功");
        this.clearqj();
        this.dialogVisible = false;
        this.$parent.getlist();
      });
    },
    clearqj() {
      this.showqj = false;
      this.qjliyou = "";
      this.qjdch = "";
      this.qjid = "";
    },
    showzc() {
      this.$refs.lookzuoci.id = this.form.id;
      this.$refs.lookzuoci.getlist();
      this.$refs.lookzuoci.show = true;
    },
    showqd() {
      // 过滤已报名人员中 已签到的人员
      this.$refs.qiandao.wqiandaoList = this.form.bmry.filter(
        (o1) => !this.form.qdry.some((o2) => o1.user_id === o2.user_id)
      );
      this.$refs.qiandao.qiandaoList = this.form.qdry;
      this.$refs.qiandao.id = this.form.id;
      this.$refs.qiandao.show = true;
    },
    showqjtr() {
      this.$refs.qingjia.qingjiaList = this.form.qjry;
      this.$refs.qingjia.show = true;
    },
    showbm() {
      // 过滤全部人员中 已报名的人员和请假人员
      let arr1 = this.form.qbry.filter(
        (o1) => !this.form.bmry.some((o2) => o1.user_id === o2.user_id)
      );
      this.$refs.baoming.wbmList = arr1.filter(
        (o1) => !this.form.qjry.some((o2) => o1.user_id === o2.user_id)
      );

      this.$refs.baoming.id = this.form.id;
      this.$refs.baoming.bmList = this.form.bmry;
      this.$refs.baoming.show = true;
    },
    getinfo() {
      this.$post({
        url: "/api/wymeeting/details",
        params: {
          id: this.form.id,
        },
      }).then((res) => {
        this.form = res;
      });
    },
    changetap(i) {
      // if(i==1){
      // 	this.$refs.yitiupolad.form = this.form
      // 	this.$refs.yitiupolad.show = true
      // }
      if (i == 1) {
        this.$refs.richengupolad.form = this.form;
        this.$refs.richengupolad.textarea = this.form.hyrc;
        this.$refs.richengupolad.show = true;
      }
      // if (i == 2) {
      // 	this.$refs.yichengupolad.form = this.form
      // 	this.$refs.yichengupolad.textarea = this.form.hyyc
      // 	this.$refs.yichengupolad.show = true
      // }
      if (i == 2) {
        let list = [];
        if (this.form.wenjian.length > 0) {
          this.form.wenjian.forEach((item, index) => {
            let obj = {
              fujian: item.fullurl,
              fujiananem: item.filename,
              id: item.id,
            };
            list.push(obj);
          });
        }

        this.$refs.fileupolad.form = this.form;
        this.$refs.fileupolad.list = list;
        this.$refs.fileupolad.show = true;
      }
      if (i == 3) {
        this.$refs.zuoxitongji.id = this.form.id;
        this.$refs.zuoxitongji.setmap();
        this.$refs.zuoxitongji.show = true;
      }

      this.tapindex = i;
    },
    showroomname(id) {
      let name = "";
      this.roomoptions.forEach((item, index) => {
        if (item.id == id) {
          name = item.name;
        }
      });
      return name;
    },
    selectedry(e) {
      if (this.selectindex == 1) {
        //出席人员
        this.form.chuser_ids = e.map((item) => {
          return item.id;
        });
        this.form.chuser_ids = this.form.chuser_ids.join(",");
        this.form.chuser_name = e.map((item) => {
          return item.name;
        });
        this.form.chuser_name = this.form.chuser_name.join(",");
      }
      if (this.selectindex == 2) {
        //列席人员
        this.form.lxuser_ids = e.map((item) => {
          return item.id;
        });
        this.form.lxuser_ids = this.form.lxuser_ids.join(",");
        this.form.lxuser_name = e.map((item) => {
          return item.name;
        });
        this.form.lxuser_name = this.form.lxuser_name.join(",");
      }
    },
    resetform() {
      this.form = {
        name: "",
        addtime: "",
        address: "",
        chuser_ids: "",
        chuser_name: "",
        lxuser_ids: "",
        lxuser_name: "",
        endtime: "",
        fw: "",
      };
      this.dialogVisible = false;
    },
    showselect(i) {
      this.selectindex = i;
      if (i == 1 && this.form.chuser_ids) {
        //出席人员
        let cxidlist = this.form.chuser_ids.split(",");
        let cxnamelist = this.form.chuser_name.split(",");
        let list = [];
        cxidlist.forEach((item, index) => {
          let obj = {
            name: cxnamelist[index],
            id: Number(item),
          };
          list.push(obj);
        });
        this.$refs.myselectuser.selectuser = list;
      }
      if (i == 2 && this.form.lxuser_ids) {
        //出席人员
        let cxidlist = this.form.lxuser_ids.split(",");
        let cxnamelist = this.form.lxuser_name.split(",");
        let list = [];
        cxidlist.forEach((item, index) => {
          let obj = {
            name: cxnamelist[index],
            id: Number(item),
          };
          list.push(obj);
        });
        this.$refs.myselectuser.selectuser = list;
      }
      this.$refs.myselectuser.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.tapmenu {
  font-size: 16px;
  margin-right: 30px;
  cursor: pointer;
  padding-bottom: 5px;
}

.active_tap {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.item_line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .tlename {
    width: 85px;
    font-size: 14px;

    text-align: right;
    color: #333;
  }

  .bticon {
    width: 10px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 34px;
    font-size: 26px;
    color: red;
  }
}

.infobox {
  width: 100%;
  padding-left: 50px;
  padding-right: 30px;

  .contbox {
    display: flex;
    padding: 20px 40px;
    height: 750px;
    transition: all 0.5s;
    overflow: hidden;
  }

  .colosecont {
    height: 0;
    padding: 0;
  }

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    color: #ec4a4a;
    border-bottom: 1px solid #ec4a4a;
  }
}

.addbox {
  height: 85vh;
  width: 100%;
  overflow-y: auto;
  padding: 30px;
  background-color: #fff;
}

.add_cont {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.btnbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  border-top: 1px solid #eee;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-form-item__label {
  color: #999;
  font-size: 14px;
}

::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "" !important;
}

::v-deep .el-input--small .el-input__icon {
  color: #333;
  font-weight: bold;
}

::v-deep .el-dialog {
  margin: 0 !important;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #eee;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #eee;
}

::v-deep .el-dialog__body {
  padding: 10px;
  padding-top: 0px;
}

::v-deep .el-tabs--border-card {
  height: 100%;
}

::v-deep .el-tabs--border-card {
  box-shadow: 0 0 0 #fff;
}

::v-deep .el-tabs__header {
  background-color: #fff;
}

::v-deep .myinputicon {
  cursor: pointer;
}

::v-deep .myinputicon:hover {
  color: red !important;
}

::v-deep .el-dialog__wrapper {
  z-index: 2000 !important;
}
</style>
