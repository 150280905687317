<template>
	<div class="person_box">
		<myheader></myheader>
		<div class="main">
			<leftmenu @resetpage="resetpage"></leftmenu>
			<div class="main_box" :class="$store.state.closeleftmenu?'main_box_mini':''">
				<history  v-if="$route.path!='/system/home'"></history>
				<router-view v-if="!iskeep()"></router-view>
				<keep-alive>
					<router-view v-if="iskeep()" ref="resetp"></router-view>
				</keep-alive>
				<!-- <pageFooter></pageFooter> -->
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import myheader from '../../components/Myhedaer.vue'
	import history from '../../components/history.vue'
	import leftmenu from "../../components/leftmenu"; //左侧菜单
	import pageFooter from "../../components/pageFooter"
	export default{
		components:{
			myheader,
			leftmenu,
			history,
			pageFooter
		},
		data(){
			return{
				
			}
		},
		methods:{
			iskeep(path){
				return this.$route.meta?.keepalive?true:false
			},
			resetpage(){
				console.log(4444)
				//有keep alive的页面 重置页数和搜索项
				setTimeout(()=>{
					
					this.$refs.resetp.reset()
				},200)
			}
		}
	}
</script>

<style lang="scss">
	.person_box{
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
		.main{
			width: 100%;
			display: flex;
			height: calc(100vh - 68px);
			margin-top: 8px;
		}
	}
	::v-deep .el-table__body{
		.el-table__row:nth-last-child(1){
			border-bottom: none !important;
			td{
				border-bottom: none !important;
			}
		}	
	}
	::v-deep .el-dialog__header{
		display: flex;
	}
	::v-deep .el-dialog__footer span{
		display: flex;
		justify-content: flex-end;
	}
	::v-deep .el-dialog__header{
		display: flex;
		border-bottom: 1px solid #e0e0e0;
	}
	::v-deep .el-dialog__footer{
		border-top: 1px solid #e0e0e0;
		padding: 10px 20px 12px;
	}
	::v-deep .el-dialog__wrapper{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	::v-deep .el-dialog{
		margin: 0;
	}
	
</style>