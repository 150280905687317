<template>
	<div class="bx second_index" style="padding: 0 2.6rem;flex-direction: column;">
		
		<div class="font23" style="color: #333333;margin-bottom: 20px;">
			<span style="color: #276DC8;font-size: 0.3rem;font-weight: bold;margin-right: 40px;">{{namelist[0]}}</span>
			<span v-for="(item,index) in erji">
				<span @click="gobackmenuej(item,index)" class="erji" :class="activeerj==item.name?'activename':''">{{item.name}}</span>
			</span>
		</div>
		
		<div class="left" style="width: 100%;">
			<div class="mianbaoxie">
				<div
					style="height: .24rem;width: .07rem;background-color: #2C65AF;border-radius: 0.04rem;margin-right: .11rem;">
				</div>
				<div class="font23">
					当前位置：
				</div>
				<div class="font23" style="color: #333333;" v-if="name!='云端委员驿站联盟>委员驿站详情'">
					<span @click="gohome" style="color: #276DC8;cursor: pointer;">首页</span>
					<span v-for="(item,index) in namelist">
						<span style="color: #0A1F44;"> > </span><span @click="gobackmenu(item,index)" style="color: #276DC8;cursor:pointer;">{{item}}</span>
					</span>
				</div>
				<div class="font23" style="color: #333333;" v-if="name=='云端委员驿站联盟>委员驿站详情'">
					<span @click="gohome" style="color: #276DC8;cursor: pointer;">首页</span>
					<span v-for="(item,index) in namelist">
						<span style="color: #0A1F44;"> > </span><span @click="showmap" :style="{color: index==0?'#276DC8':'#333',cursor:index==0?'pointer':'auto'}">{{item}}</span>
					</span>
				</div>
			</div>
			<!-- &&name!='政协概况>委员名录' -->
			<div v-show="name!='政协概况>政协领导'&&name!='政协概况>政协简介'&&name!='云端委员驿站联盟'&&name!='云端委员驿站联盟>委员驿站详情'&&name!='机构设置'&&name!='政协概况>机构设置'">
				<div class="content" style="padding: 0.37rem 0.68rem;" v-show="!showdetial">
					<block v-if="erji.length>0&&!activeerj" :erjilist="erji" :father="namelist[0]">
					
					</block>
					
					<blockhy v-if="name=='专题专栏>全体会议'">
					
					</blockhy>
					
					<div  v-if="name!='专题专栏>全体会议'&&!(erji.length>0&&!activeerj)">
						<div v-for="(item, index) in dataList" :key="index" @click="look(item)" class="font23 flex-sb" style="color: #276DC8;">
							<div class="title font23 flex" style="font-size: 18px;">
								<div class="dian"
									style="width: 0.05rem;height: 0.05rem;background-color: #2C65AF;margin-top: .14rem;margin-right: 0.14rem;flex-shrink: 1;">
								</div>{{ item.name }}
							</div>
							<div class="time font23" style="font-size: 18px;">{{ item.fbtime }}</div>
						</div>
					</div>
					
				</div>
				<div class="content" v-if="showdetial">
					<div style="color: #2C65AF;font-size:28px;text-align:center;">{{detail.name}}</div>
					<div style="width: 100%;margin: 10px 0;padding: 10px 0;background-color: #F3F3F3;display: flex;align-items: center;justify-content: space-around;">
						<div style="color: #666666;font-size:16px;">来源：{{detail.laiyuan}}</div>
						<div style="color: #666666;font-size:16px;">发布时间：{{detail.fbtime?detail.fbtime:detail.createtime}}</div>
					</div>
					<!-- <div style="font-size: 20px;padding: 0 .6rem;">
						{{detail.jianjie}}
					</div> -->
					<div class="myhtml" v-html="detail.content" style="font-size: 20px;line-height: 1.5;padding: 0 .6rem;">
						
					</div>
					<div style="display: flex;font-size: 0.18rem;" v-show="detail.fujian&&detail.fujian.length>0">
						<div>附件列表：</div>
						<div>
							<div v-for="item in detail.fujian" @click="openpage(item)" style="margin-bottom: 10px;color: #0000ff;cursor: pointer;">
								{{item.filename}}
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<!-- name=='政协概况>委员名录'|| -->
			<div v-show="name=='政协概况>政协简介'||name=='政协概况>政协领导'||name=='机构设置'||name=='政协概况>机构设置'">				
				<div class="content" v-if="dataList[0]">
					<div style="color: #2C65AF;font-size:28px;text-align:center;">{{dataList[0].name}}</div>
					<div style="width: 100%;margin: 10px 0;padding: 10px 0;background-color: #F3F3F3;display: flex;align-items: center;justify-content: space-around;">
						<div style="color: #666666;font-size:16px;">来源：{{dataList[0].laiyuan}}</div>
						<div style="color: #666666;font-size: 16px;">发布时间：{{dataList[0].fbtime}}</div>
					</div>
					<!-- <div style="font-size: 20px;padding: 0 .6rem;">
						{{detail.jianjie}}
					</div> -->
					<div class="myhtml" :class="name=='政协概况>政协领导'?'hrefurl':''" v-html="dataList[0].content" @click="handleClick" style="font-size: 20px;line-height: 1.5;padding: 0 .6rem;">
						
					</div>
					<div style="display: flex;font-size: 0.18rem;" v-show="dataList[0].fujian&&dataList[0].fujian.length>0">
						<div>附件列表：</div>
						<div>
							<div v-for="item in dataList[0].fujian" @click="openpage(item)" style="margin-bottom: 10px;color: #0000ff;cursor: pointer;">
								{{item.filename}}
							</div>
						</div>
						
					</div>
				</div>
			</div>
			
			<div v-show="name=='云端委员驿站联盟'">
				<yunyz @changename="changename"></yunyz>
			</div>
			<div v-show="name=='云端委员驿站联盟>委员驿站详情'">
				<div class="content" style="padding: 10px 25px;">
					<div style="display: flex;align-items: stretch;justify-content: space-between;">
						<div style="width: 60%;height: auto;min-height:480px;" class="ysswiper">
							<el-carousel indicator-position="none" height="100%" arrow="always">
							    <el-carousel-item v-for="item in yz_detail.image" :key="item">
							      <img :src="item.fullurl" alt="" style="width: 100%;height: 100%;"/>
							    </el-carousel-item>
							  </el-carousel>							
						</div>
						<div style="width: 39%;height: auto;min-height:480px;border: 1px solid #eee;padding: 0.2rem;">
							<div style="color: #333333;font-size: 0.23rem;">驿站介绍</div>
							<div style="width: 1.6rem;height: 3px;background-color: #276DC8;margin-top: 8px;"></div>
							<div style="width: 100%;height: 2px;background-color: #4E98F1;margin-top: 2px;"></div>
							<div style="font-size: 0.18rem;">
								<p>负责人：{{yz_detail.fzren}}</p>
								<p>联系电话：{{yz_detail.lxphone}}</p>
								<p>地       址：{{yz_detail.address}}</p>
								<p>简       介：{{yz_detail.jianjie}}</p>						
							</div>
						</div>
					</div>
					<div style="width: 100%;display: flex;justify-content: space-between;margin-top: 20px;">
						<div style="width: 100%;height: auto;cursor: pointer;" @click="go360" v-show="yz_detail.id==21">
							<img src="../../../../src/assets/360du.png" alt="" style="width:100%;height:100%;"/>
						</div>
						<!-- 
						<div style="width: 23%;height: 1.68rem;">
							<img src="../../../../src/assets/hdyy.png" alt="" style="width:100%;height:100%;"/>
						</div> -->
					</div>
					
					
					<div style="width: 100%;border: 1px solid #eee;padding: 0.2rem;margin-top: 20px;">
						<div style="color: #333333;font-size: 0.23rem;">活动新闻</div>
						<div style="width: 1.6rem;height: 3px;background-color: #276DC8;margin-top: 8px;"></div>
						<div style="width: 100%;height: 2px;background-color: #4E98F1;margin-top: 2px;"></div>
						
						<div style="width: 100%;margin-top: 10px;display: flex;overflow-x: auto;">
							<div style="width: 3.5rem;padding: 0.2rem;background-color: #F3F8FF;margin-right: 0.16rem;cursor: pointer;" v-for="item in yz_news" @click="showyznews(item)">
								<img :src="item.slturl[0]?item.slturl[0].fullurl:require('../../../assets/home/huiyi.png')" alt="" style="width: 100%;height: 220px;"/>
								<div style="color: #252525;font-size: 0.22rem;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;margin-top: 8px;">
									{{item.name}}
								</div>
								<div style="margin-top: 8px;color: #898989;font-size: 0.2rem;text-overflow: ellipsis;overflow: hidden; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;">
									{{item.jianjie}}
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<!-- &&name!='政协概况>委员名录' -->
			<el-pagination v-show="!showdetial&&name!='云端委员驿站联盟>委员驿站详情>新闻详情'&&name!='政协概况>政协简介'&&name!='政协概况>政协领导'&&name!='云端委员驿站联盟'&&name!='云端委员驿站联盟>委员驿站详情'&&name!='政协概况>机构设置'&&name!='机构设置'&&(erji.length<=0||activeerj)&&name!='专题专栏>全体会议'" background
				layout="prev, pager, next" :page-size="20" :current-page="nowpage" :total="total"
				@current-change="getlist">
			</el-pagination>
		</div>
		<!-- <zxGaikuang v-show="!showdetial"/> -->
	</div>
</template>

<script>
	import mapdata from '../../../assets/xns.json';
	import zxGaikuang from '../homeComponents/zxGaikuang.vue';
	import yunyz from './yun_yz.vue';
	import block from './block.vue';
	import blockhy from './blockhy.vue';
	export default {
		components: {
			zxGaikuang,
			yunyz,
			block,
			blockhy
		},
		mounted() {
		    document.title = '西宁市政协';
		},
		data() {
			return {
				dataList: [],
				name: '',
				namelist:[],
				nowpage: 1,
				total: 0,
				showdetial: false,
				showhtml: '',
				detail:'',
				yz_detail:'',//驿站详情
				yz_news:'',//驿站新闻
				menulist:[],
				erji:[],//二级菜单列表
				activeerj:''
			}
		},
		
		watch: {
			'$route.query.name': {
				handler(newQuery, oldQuery) {
					this.erji = []
					this.showdetial = false
					this.name = this.$route.query.name
					this.namelist = this.$route.query.name.split('>')
					if(this.namelist[0]){
						this.$post({
							url: '/api/classify/index',
							params: { 
								pid:0,
								type:1,
								types:2,
								p: 1,
								size: 999
							}
						}).then((res) => {
							this.menulist = res.list	
							this.menulist.forEach((item,index)=>{
								if(item.name==this.namelist[0]){
									this.erji = item.ej
								}
							})
						})
					}
					if(this.namelist[1]){
						this.activeerj = this.namelist[1]
					}else{
						this.activeerj = ''
					}
					this.getlist()
				},
				immediate: true, // 立即执行一次，捕获初始的 URL 参数
				deep: true // 深度监听，可以捕获参数对象内部值的变化
			},
			'$route.query.detail_id': {
				handler(newQuery, oldQuery) {
					if(this.$route.query.detail_id){
						this.getdetial()
					}
				},
				immediate: true, // 立即执行一次，捕获初始的 URL 参数
				deep: true // 深度监听，可以捕获参数对象内部值的变化
			},
			'$route.query.detail_yz_id': {
				handler(newQuery, oldQuery) {
					if(this.$route.query.detail_yz_id){
						this.getdetialyz()
					}
				},
				immediate: true, // 立即执行一次，捕获初始的 URL 参数
				deep: true // 深度监听，可以捕获参数对象内部值的变化
			}
		},
		
		methods: {
			gobackmenuej(item,index){
				this.$router.push({ path: '/secondList', query: {id:item.id,name:this.namelist[0]+'>'+item.name} });
				
			},
			gobackmenu(item,index){
				if(this.namelist[index]=='全体会议'){
					this.$router.push({ path: '/secondList', query: {id:23,name:'专题专栏>全体会议'} });
					return
				}
				if(this.$route.query.name.includes('>')&&index==0){//点击的中间的
					let name = ''
					let id = ''
					this.menulist.forEach((item2,index2)=>{						
						if(item2.name==item){
							name = item2.name
							id = item2.id
						}
					})
					this.$router.push({ path: '/secondList', query: {id:id,name:name} });
				}else{//点击的最后一级
					this.$router.push({ path: '/secondList', query: {id:this.$route.query.id,name:this.$route.query.name} });
				}
				
			},
			gohome(){
				this.$router.push('/')
			},
			handleClick(event){
				if(this.name=='政协概况>政协领导'){
					const img = event.target;
					    if (img.tagName === 'IMG') {
					      const dataHref = img.getAttribute('data-href');
					      
						  const currentParams = this.$route.query;
						  currentParams.name = currentParams.name+'>详情'
						  const newParams = { ...currentParams, detail_id:dataHref };	
						  this.$router.push({ path: '/secondList', query: newParams });
					      
					    }
				}
				
			},
			showyznews(row){
				// 获取当前路由的参数 追加详情id
				const currentParams = this.$route.query;	
				currentParams.name = this.name +'>新闻详情'
				const newParams = { ...currentParams, detail_yz_id:row.id };	
				this.$router.push({ path: '/secondList', query: newParams });
			},
			openpage(item){
				window.open(item.fullurl,'_blank')
			},
			go360(){
				window.open('https://www.720yun.com/t/edakn7rygdw',"_blank")
			},
			godetail(){
				this.name = '云端委员驿站联盟>委员驿站详情'
			},
			showmap(){
				this.name='云端委员驿站联盟'
				this.namelist = this.name.split('>')
			},
			look(row) {		
				if(row.tzurl){
					window.open(row.tzurl,'_blank')
					return
				}
				
				if(this.name=='提案公开>提案公开'){
					if(row.fujian.length>0){
						row.fujian.forEach((item)=>{
							window.open(item.fullurl,"_blank")
						})						
					}else{
						this.$message.warning('暂未上传附件')
					}
					return
				}
				console.log(this.$route.fullPath)
				// 获取当前路由的参数 追加详情id
				const currentParams = this.$route.query;	
				const newParams = { ...currentParams, detail_id:row.id };	
				this.$router.push({ path: '/secondList', query: newParams });
				
				// this.detail = row				
				// this.showdetial = true
				
			},
			changename(e){
				this.name = e.name
				this.namelist = e.name.split('>')
				this.$post({//驿站详情
					url: '/api/stage_pc/details',
					params: {
						id: e.id,
					}
				}).then((res) => {
					this.yz_detail = res
				})
				
				
				this.$post({//驿站新闻
					url: '/api/stage_pc/stage_news_index',
					params: {
						stage_id: e.id,
					}
				}).then((res) => {
					this.yz_news = res.list
				})
				
			},
			getlist(e) {
				this.nowpage = e ? e : 1
				this.$post({
					url: '/api/news/index',
					params: {
						id: this.$route.query.id,
						p: this.nowpage,
						size: 20
					}
				}).then((res) => {
					this.dataList = res.list
					this.total = res.count
				})
			},
			getdetial(){
				this.$post({
					url: '/api/news/details',
					params: {
						id: this.$route.query.detail_id
					}
				}).then((res) => {
					this.showdetial = true
					this.detail = res
				})
			},
			getdetialyz(){
				this.$post({
					url: '/api/stage_news/details',
					params: {
						id: this.$route.query.detail_yz_id
					}
				}).then((res) => {
					this.showdetial = true
					this.detail = res
				})
			}
		},
		created() {
			this.namelist = this.$route.query.name.split('>')
			this.name = this.$route.query.name 
			if(this.$route.query.detail_id){
				this.getdetial()
			}else{
				this.getlist()
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.erji{
		color: #666;
		cursor:pointer;
		margin-right: 20px;
		font-size: 0.2rem;
		font-weight: bold;
	}
	.erji:hover{
		color: #ef4569;
	}
	.activename{
		color: #ef4569;
	}
	::v-deep .el-pagination{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	::v-deep .content p {
		margin: 15px 0;
	}
	::v-deep .myhtml{
		p{
			font-size: 18px;
			text-align: justify;
		}
		img{
			max-width: 100% !important;
		}
	} 
	.second_index {
		display: flex;
		justify-content: space-between;

		.left {
			width: 75.4%;

			.mianbaoxie {
				padding: .14rem .24rem;
				width: 100%;
				background: #EDEDED;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex: 1;
			}

			.content {
				width: 100%;
				background: #fff;
				box-sizing: border-box;
				padding: .25rem;
				min-height: 60vh;

				.flex-sb {
					align-items: flex-start;
					margin-bottom: 0.23rem;


					.title {
						width: 79%;
					}

					.tiem {
						flex: 2.02rem;
						margin-left: 0.14rem;
					}
				}

				.flex-sb:hover {
					cursor: pointer;
					color: rgb(52, 124, 214) !important;
				}

			}
		}
	}

	::v-deep .el-pagination {
		text-align: center !important;
	}
	::v-deep .hrefurl{
		img{
			cursor: pointer;
		}
	}
	::v-deep .ysswiper .el-carousel--horizontal{
		height: 100%;
	}
</style>