<template>
  <div class="box box_mm">
    <div class="box_main">
      <div class="search_box">
        <div class="search_item">
          <span style="width: 0.6rem">名称：</span>
          <el-input
            v-model="searchinput"
            placeholder="请输入名称"
            style="width: 2rem"
          ></el-input>
        </div>
        <!-- <div class="search_item">
          <span style="width: 0.8rem">会议类型：</span>
          <el-select
            v-model="searchnian"
            placeholder="请选择"
            filterable
            clearable
            style="width: 2rem"
            @change="search"
          >
            <el-option
              v-for="(item, index) in hylxlist"
              :label="item.name"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
        </div> -->
        <div class="search_item">
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="success" @click="addnew">新增会议</el-button>
        </div>
      </div>
      <div class="box_cont">
        <div class="btn_list"></div>
        <div style="height: 76vh; box-sizing: border-box">
          <MyTable
            ref="zjlist"
            :tableData="tableData"
            :tableHeader="tableHeader"
            :showbm="true"
            :czwidth="420"
            @getdata="gettable"
            @changswitch="changswitch"
          >
            <template slot="btns" slot-scope="{ scope }">
              <el-button
                v-if="scope.row.stype == 2"
                @click="showeidt(scope.row)"
                type="success"
                size="mini"
                >详情</el-button
              >
              <el-button @click="goedit(scope.row)" type="warning" size="mini"
                >编辑</el-button
              >
              <el-button
                v-if="scope.row.stype == 2"
                @click="setpailie(scope.row)"
                type="primary"
                size="mini"
                >排座位</el-button
              >
              <el-button
                v-if="scope.row.stype == 2"
                @click="lookpailie(scope.row)"
                type="primary"
                size="mini"
                >查看座位</el-button
              >
              <el-button
                v-if="scope.row.stype == 2"
                @click="qdcode(scope.row)"
                type="primary"
                size="mini"
                >签到二维码</el-button
              >
            </template>
          </MyTable>
        </div>
      </div>
    </div>

    <detail ref="mydetail" :updataQx="2"></detail>
    <setmap ref="mysetmap"></setmap>
    <looksetmap ref="mylooksetmap"></looksetmap>

    <!--签到二维码-->
    <el-dialog title="签到二维码" :visible.sync="showcode" width="400px">
      <div
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <vue-qr
          :logoSrc="imageLogo"
          :text="qr_url"
          :size="300"
          :margin="5"
          colorDark="black"
          colorLight="white"
        ></vue-qr>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; align-items: center; justify-content: flex-end"
      >
        <el-button @click="showcode = false">关 闭</el-button>
        <!-- <el-button type="primary" @click="postqingjia">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import setmap from "./components/set_map_set.vue";
import looksetmap from "./components/look_map_set.vue";
import detail from "./components/detail.vue";
import vueQr from "vue-qr";
export default {
  components: { detail, setmap, vueQr,looksetmap },
  data() {
    return {
      searchinput: "",
      tableHeader: [
        {
          prop: "id",
          name: "序",
          width: "50",
        },
        {
          prop: "name",
          name: "会议名称",
          width: "",
        },
        {
          prop: "room_name",
          name: "会议室",
          width: "200",
        },
        {
          prop: "room_address",
          name: "地点",
          width: "",
        },
        {
          prop: "wymeetingtype_name",
          name: "会议类型",
          width: "",
        },
        {
          prop: "addtime",
          name: "开始时间",
          width: "200",
        },
        {
          prop: "stype",
          name: "状态",
          width: "120",
          type: "hystatus",
        },
      ],
      tableData: [],
      hylxlist: [], //会议类型
      showcode: false,
      qr_url: "",
      imageLogo: require("@/assets/logo.png"),
    };
  },
  mounted() {
    this.gettable();
  },
  activated() {
    this.gettable(); // 每次进入页面时刷新数据
    this.gethylxlist();
  },
  methods: {
    gethylxlist() {
      this.$post({
        url: "/api/wymeetingtype/index",
        params: {},
      }).then((res) => {
        this.hylxlist = res.list;
      });
    },
    goedit(row) {
      this.$router.push({
        path: "/system/meeting_edit",
        query: { id: row.id },
      });
    },
    qdcode(row) {
      this.qr_url =
        this.$URL +
        "?path=/pages/home/meeting/detail/detail&id=" +
        row.id +
        "&type=scan";
      this.showcode = true;
    },
    setpailie(row) {
      this.$post({
        url: "/api/wyroomzc/hyzc2hui",
        params: {
          wymeeting_id: row.id,
        },
      }).then((res) => {
        console.log(res);
        this.$refs.mysetmap.userlist = [];
        if (res.list.length >= 1) {
          this.$refs.mysetmap.userlist = res.list.map((item) => {
            let obj = {
              user_id: item.user_id,
              name: item.name,
            };
            return obj;
          });
        }
        this.$refs.mysetmap.metting_id = row.id;
        if (res.zuoci.pai.length == 0) {
          this.$refs.mysetmap.init();
          this.$refs.mysetmap.dialogVisible = true;
        } else if (res.zuoci.pai[0]?.lie.length == 0) {
          this.$refs.mysetmap.init();
          this.$refs.mysetmap.dialogVisible = true;
        } else {
          let list = [];
          res.zuoci.pai.forEach((item, index) => {
            list[index] = [];
            item.lie.forEach((item2, index2) => {
              item2.name = item2.user ? item2.user.name : "";
              list[index].push(item2);
            });
          });
          // console.log('list',list);
          list.forEach((item) => {
            let num = 0;
            item.forEach((ite) => {
              if (ite.status == 1) {
                ite.idx = num;
                num++;
              }
            });
          });
          let zxlist = [];
          res.zuoci.zhu.forEach((item, index) => {
            zxlist[index] = [];
            item.lie.forEach((item2, index2) => {
              item2.name = item2.user ? item2.user.name : "";
              zxlist[index].push(item2);
            });
          });
          // console.log('zxlist',zxlist);
          zxlist.forEach((item) => {
            let num = 0;
            item.forEach((ite) => {
              if (ite.status == 1) {
                ite.idx = num;
                num++;
              }
            });
          });

          // zxlist = zxlist.reverse()
          this.$refs.mysetmap.list = list;
          this.$refs.mysetmap.zxset = zxlist;
          this.$refs.mysetmap.dialogVisible = true;
        }
      });
    },
    lookpailie(row) {
      this.$post({
        url: "/api/wyroomzc/hyzc2hui",
        params: {
          wymeeting_id: row.id,
        },
      }).then((res) => {
        this.$refs.mylooksetmap.userlist = [];
        if (res.list.length >= 1) {
          this.$refs.mylooksetmap.userlist = res.list.map((item) => {
            let obj = {
              user_id: item.user_id,
              name: item.name,
            };
            return obj;
          });
        }
        this.$refs.mylooksetmap.metting_id = row.id;
        if (res.zuoci.pai.length == 0) {
          this.$refs.mylooksetmap.init();
          this.$refs.mylooksetmap.dialogVisible = true;
        } else if (res.zuoci.pai[0]?.lie.length == 0) {
          this.$refs.mylooksetmap.init();
          this.$refs.mylooksetmap.dialogVisible = true;
        } else {
          let list = [];
          res.zuoci.pai.forEach((item, index) => {
            list[index] = [];
            item.lie.forEach((item2, index2) => {
              item2.name = item2.user ? item2.user.name : "";
              list[index].push(item2);
            });
          });
          // console.log('list',list);
          list.forEach((item) => {
            let num = 0;
            item.forEach((ite) => {
              if (ite.status == 1) {
                ite.idx = num;
                num++;
              }
            });
          });
          let zxlist = [];
          res.zuoci.zhu.forEach((item, index) => {
            zxlist[index] = [];
            item.lie.forEach((item2, index2) => {
              item2.name = item2.user ? item2.user.name : "";
              zxlist[index].push(item2);
            });
          });
          // console.log('zxlist',zxlist);
          zxlist.forEach((item) => {
            let num = 0;
            item.forEach((ite) => {
              if (ite.status == 1) {
                ite.idx = num;
                num++;
              }
            });
          });

          // zxlist = zxlist.reverse()
          this.$refs.mylooksetmap.list = list;
          this.$refs.mylooksetmap.zxset = zxlist;
          this.$refs.mylooksetmap.dialogVisible = true;
        }
      });
    },
    showeidt(row) {
      this.$post({
        url: "/api/wymeeting/details",
        params: {
          id: row.id,
        },
      }).then((res) => {
        this.$refs.mydetail.form = res;
        this.$refs.mydetail.isedite = false;
        this.$refs.mydetail.dialogVisible = true;
      });
    },
    changswitch(row) {
      this.$post({
        url: "/api/room/edit",
        params: row,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.gettable();
      });
    },
    search() {
      this.$refs.zjlist.inputpage = 1;
      this.gettable();
    },
    reset() {
      this.searchinput = "";
      this.$refs.zjlist.inputpage = 1;
      this.gettable();
    },
    gettable() {
      this.$post({
        url: "/api/wymeeting/index",
        params: {
          type: 1,
          qxtype: 1,
          p: this.$refs.zjlist.inputpage,
          size: this.$refs.zjlist.pagesize,
          name: this.searchinput,
        },
      }).then((res) => {
        this.tableData = res.list;
        this.$refs.zjlist.total = res.count;
      });
    },
    addnew() {
      this.$router.push("/system/meeting_add");
    },
    showdate(row) {
      this.$refs.myroomdate.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "meeting_list.scss";
</style>
